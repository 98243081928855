import { useContext } from "react";
import { TextInputField, Button } from ".";
import { Theme } from "../Theme";
import { Formik, Field, FieldProps, Form } from "formik";
import * as Yup from "yup";
import { IUpdateGuestData, TableContext, guestExists } from "../Context/TableContext";
import { AiFillDelete } from "react-icons/ai";
import { selectGuestOpenInModal } from "../Selectors";
import { GuestFormat, TGuest } from "../Context/tableReducer";
import { useUpdateGuest } from "../CustomHooks/useUpdateGuest";
import { useDeleteGuest } from "../CustomHooks/useDeleteGuest";
import { AlignColumnItemsCenter, StyledInputError, StyledText } from "../Styles";
import { getGuestId, isNotWhitespace } from "../Utils";

interface IEditTableFormProps {
  closeModal: () => void;
}
const textInputStyle = {
  inputFieldStyle: { focusOutlineStyle: `solid ${Theme.colors.darkBlue} 2px`, height: "35px", backgroundColor: Theme.colors.white },
  inputFieldAndErrorDisplayContainerStyle: { width: "100%", marginTop: "15px" },
};
const buttonStyles = {
  width: "100%",
  color: Theme.colors.white,
  borderRadius: "15px",
  height: "40px",
  marginTop: "20px",
};

const removeWhiteSpaceFromInputValues = (values: IUpdateGuestData): IUpdateGuestData => {
  if (values.format === GuestFormat.Format1) {
    return {
      ...values,
      title: values.title.trim(),
      initial: values.initial.trim(),
      lastName: values.lastName.trim(),
    };
  }
  return {
    ...values,
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
  };
};

const iconStyle = { width: 20, height: 20, cursor: "pointer", color: Theme.colors.blackGrey, marginLeft: 4 };

const renderFields = (setStatus: (arg: any) => void, handleChange: (arg: any) => void, format?: GuestFormat) => {
  const handleChangeAndResetStatus = (event: React.ChangeEvent<any>) => {
    setStatus(null);
    handleChange(event);
  };
  if (format === GuestFormat.Format1) {
    return (
      <>
        <Field name="title">
          {({ field, meta }: FieldProps) => {
            return (
              <TextInputField
                label="Title"
                overrideStyles={textInputStyle}
                {...field}
                {...meta}
                placeholder="Enter title"
                type="string"
                onChange={handleChangeAndResetStatus}
              />
            );
          }}
        </Field>
        <Field name="initial">
          {({ field, meta }: FieldProps) => {
            return (
              <TextInputField
                label="Initial"
                overrideStyles={textInputStyle}
                {...field}
                {...meta}
                placeholder="Enter intitial"
                type="string"
                onChange={handleChangeAndResetStatus}
              />
            );
          }}
        </Field>
        <Field name="lastName">
          {({ field, meta }: FieldProps) => {
            return (
              <TextInputField
                label="Surname"
                overrideStyles={textInputStyle}
                {...field}
                {...meta}
                placeholder="Enter surname"
                type="string"
                onChange={handleChangeAndResetStatus}
              />
            );
          }}
        </Field>
      </>
    );
  }
  if (format === GuestFormat.Format2) {
    return (
      <>
        <Field name="firstName">
          {({ field, meta }: FieldProps) => {
            return (
              <TextInputField label="First name" overrideStyles={textInputStyle} {...field} {...meta} placeholder="Enter first name" type="string" />
            );
          }}
        </Field>
        <Field name="lastName">
          {({ field, meta }: FieldProps) => {
            return <TextInputField label="Surname" overrideStyles={textInputStyle} {...field} {...meta} placeholder="Enter surname" type="string" />;
          }}
        </Field>
      </>
    );
  }
};

const buildInitialValues = (guest: TGuest): IUpdateGuestData | {} => {
  if (!guest) {
    return {};
  }
  if (guest.format === GuestFormat.Format1) {
    return {
      title: guest.title,
      initial: guest.initial,
      lastName: guest.lastName,
      format: guest.format,
    };
  }
  if (guest.format === GuestFormat.Format2) {
    return {
      firstName: guest.firstName,
      lastName: guest.lastName,
      format: guest.format,
    };
  }
  throw new Error("Invalid guest format");
};

const buildValidationSchema = (guest: TGuest) => {
  if (guest?.format === GuestFormat.Format1) {
    return Yup.object().shape({
      title: Yup.string().required("Required").test("not-whitespace", "Cannot be empty or whitespace", isNotWhitespace),
      initial: Yup.string().required("Required").test("not-whitespace", "Cannot be empty or whitespace", isNotWhitespace),
      lastName: Yup.string().required("Required").test("not-whitespace", "Cannot be empty or whitespace", isNotWhitespace),
    });
  }
  if (guest?.format === GuestFormat.Format2) {
    return Yup.object().shape({
      firstName: Yup.string().required("Required").test("not-whitespace", "Cannot be empty or whitespace", isNotWhitespace),
      lastName: Yup.string().required("Required").test("not-whitespace", "Cannot be empty or whitespace", isNotWhitespace),
    });
  }
  return {};
};

export const EditGuestForm = ({ closeModal }: IEditTableFormProps) => {
  const { state } = useContext(TableContext);
  const { updateGuest } = useUpdateGuest();
  const { deleteGuest } = useDeleteGuest();
  const showPendingChangesMustBeSaved = state.guestsNeedSaving;
  const guestOpenInModal = selectGuestOpenInModal(state);
  const format = guestOpenInModal?.format;
  const saveChangesBeforeEdit = "Pending changes must be saved before editing guest details.";

  if (showPendingChangesMustBeSaved) {
    return (
      <AlignColumnItemsCenter>
        <StyledText textStyle="regular" textAlign="justify" lineHeight="23px">
          {saveChangesBeforeEdit}
        </StyledText>
        <Button
          text="Back"
          onPress={closeModal}
          buttonStyles={{
            ...buttonStyles,
            alignSelf: "centet",
            width: "250px",
          }}
        />
      </AlignColumnItemsCenter>
    );
  }

  return (
    <Formik
      initialValues={buildInitialValues(guestOpenInModal)}
      onSubmit={async (values, { setStatus }) => {
        if (Object.keys(values).length === 0) {
          return;
        }
        let args = removeWhiteSpaceFromInputValues(values as IUpdateGuestData);
        let newGuestId: string;
        if (args.format === GuestFormat.Format1) {
          newGuestId = getGuestId({ initial: args.initial, title: args.title, lastName: args.lastName, format: GuestFormat.Format1 });
        } else {
          newGuestId = getGuestId({ firstName: args.firstName, lastName: args.lastName, format: GuestFormat.Format2 });
        }
        if (guestOpenInModal.id === newGuestId) {
          await updateGuest(args, newGuestId);
          closeModal();
          return;
        }
        if (guestExists(state.tables, newGuestId) && guestOpenInModal.id) {
          setStatus("A guest with the above details already exists");
          return;
        }
        await updateGuest(args, newGuestId);
        closeModal();
      }}
      validationSchema={buildValidationSchema(guestOpenInModal)}
    >
      {({ isValid, status, setStatus, handleChange }) => {
        return (
          <>
            <Form>
              {renderFields(setStatus, handleChange, format)}
              {status && <StyledInputError inputErrorStyle={{ marginTop: "10px" }}>{status}</StyledInputError>}
              <Button type="submit" text="Save" buttonStyles={buttonStyles} isLoading={state.updatingGuest} disabled={!isValid} />
            </Form>
            <Button
              text="Delete"
              disabled={state.updatingGuest}
              isLoading={state.deletingGuest}
              onPress={async () => {
                await deleteGuest(guestOpenInModal.id, state.tableOfguestOpenInModal);
                closeModal();
              }}
              buttonStyles={{ ...buttonStyles, backgroundColor: "rgb(228,48,63)" }}
              Icon={() => {
                return <AiFillDelete style={iconStyle} />;
              }}
            />
          </>
        );
      }}
    </Formik>
  );
};
