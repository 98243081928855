import { FirebaseError } from "@firebase/util";
import { createContext, useEffect, useReducer } from "react";
import {
  authReducer,
  IAuthReducerState,
  USER_SIGN_UP_FAILURE,
  USER_SIGN_UP_PENDING,
  USER_SIGN_IN_PENDING,
  USER_SIGN_IN_FAILURE,
  NAVIGATE_FROM_AUTH_FORM,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_PENDING,
  USER_LOGOUT_FAILURE,
  USER_AUTH_PENDING,
  USER_AUTH_SUCCESS,
  IUser,
  USER_AUTH_FAILURE,
  USER_UPDATE_FAILURE,
  USER_UPDATE_PENDING,
  IUserUpdateableFields,
  USER_UPDATE_SUCCESS,
} from "./AuthReducer";
import { User, createUserWithEmailAndPassword, deleteUser, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../Firebase";
import { addUser, getUser, updateUser as updateUserInFirestore } from "../Firebase/firestore";
import Bugsnag from "@bugsnag/js";
import { logger } from "../Logger";

interface IBasicAuth {
  email: string;
  password: string;
}

interface ISignupArgs extends IBasicAuth {
  firstName: string;
  lastName: string;
  discountCode?: string;
}

interface IAuthContext {
  state: IAuthReducerState;
  signup: (args: ISignupArgs) => Promise<void>;
  signin: (args: IBasicAuth) => Promise<void>;
  updateUser: (args: IUserUpdateableFields) => Promise<void>;
  logout: () => Promise<void>;
  navigateFromAuthForm: () => void;
}
export const AuthContext = createContext<IAuthContext>({
  state: {
    user: undefined,
    signupLoading: false,
    signinLoading: false,
    logoutLoading: false,
    signupError: undefined,
    signinError: undefined,
    logoutError: undefined,
    authPending: true,
    updatePending: false,
    updateError: undefined,
  },
  signup: () => {
    throw new Error("AuthContext not initialized");
  },
  updateUser: () => {
    throw new Error("AuthContext not initialized");
  },
  signin: () => {
    throw new Error("AuthContext not initialized");
  },
  logout: () => {
    throw new Error("AuthContext not initialized");
  },
  navigateFromAuthForm: () => {},
});

const initialState = {
  user: undefined,
  signupLoading: false,
  signinLoading: false,
  logoutLoading: false,
  signupError: undefined,
  signinError: undefined,
  logoutError: undefined,
  authPending: true,
  updatePending: false,
  updateError: undefined,
};

export const fetchUser = async (userId: string, maxWaitTime = 10000): Promise<IUser> => {
  const startTime = Date.now();
  while (Date.now() - startTime < maxWaitTime) {
    logger.log("fetching user");
    const user = (await getUser(userId)) as IUser;
    if (!user) {
      continue;
    }
    if (user.processingComplete) {
      return user;
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
  }

  throw new Error("Timeout waiting for user document processing");
};

export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser: User | null) => {
      dispatch({
        type: USER_AUTH_PENDING,
      });
      if (firebaseUser) {
        try {
          Bugsnag.setUser(firebaseUser.uid);
          let user = await fetchUser(firebaseUser.uid);
          dispatch({
            type: USER_AUTH_SUCCESS,
            user: { ...user, uid: firebaseUser.uid },
          });
        } catch (e) {
          logger.error(e);
          dispatch({
            type: USER_AUTH_FAILURE,
            error: e instanceof FirebaseError ? e.code : "Unknown error",
          });
        }
      } else {
        dispatch({
          type: USER_LOGOUT_SUCCESS,
        });
      }
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signup = async (args: ISignupArgs) => {
    const { email, password } = args;
    try {
      dispatch({
        type: USER_SIGN_UP_PENDING,
      });
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (!userCredential.user) {
        throw new Error("Could not complete signup");
      }
      try {
        await addUser({
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          firstName: args.firstName,
          lastName: args.lastName,
          ...(args.discountCode ? { discountCode: args.discountCode } : {}),
        });
      } catch (e) {
        logger.error(e);
        await deleteUser(userCredential.user);
        throw e;
      }
    } catch (e) {
      logger.error(e);
      dispatch({
        type: USER_SIGN_UP_FAILURE,
        error: e instanceof FirebaseError ? e.code : "Unknown error",
      });
    }
  };

  const signin = async (args: IBasicAuth) => {
    const { email, password } = args;
    try {
      dispatch({
        type: USER_SIGN_IN_PENDING,
      });
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredential.user) {
        throw new Error("There was a problem signing into your account ");
      }
    } catch (e) {
      logger.error(e);
      dispatch({
        type: USER_SIGN_IN_FAILURE,
        error: e instanceof FirebaseError ? e.code : "Unknown error",
      });
    }
  };

  const logout = async () => {
    try {
      dispatch({
        type: USER_LOGOUT_PENDING,
      });
      await auth.signOut();
    } catch (e) {
      logger.error(e);
      dispatch({
        type: USER_LOGOUT_FAILURE,
        error: e instanceof FirebaseError ? e.code : "Unknown error",
      });
    }
  };

  const navigateFromAuthForm = () => {
    dispatch({
      type: NAVIGATE_FROM_AUTH_FORM,
    });
  };

  const updateUser = async (args: IUserUpdateableFields) => {
    try {
      dispatch({
        type: USER_UPDATE_PENDING,
      });
      const userId = state.user?.uid;
      if (!userId) {
        throw new Error("Trying to update a user when no userId has been set in state");
      }
      await updateUserInFirestore(args, userId);
      dispatch({
        type: USER_UPDATE_SUCCESS,
        userUpdateableFields: args,
      });
    } catch (e) {
      logger.error(e);
      dispatch({
        type: USER_UPDATE_FAILURE,
        error: "Update failed. If the problem persists please contact support@simplifytables.com",
      });
    }
  };
  return <AuthContext.Provider value={{ state, signup, signin, logout, navigateFromAuthForm, updateUser }}>{children}</AuthContext.Provider>;
};
