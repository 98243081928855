import { DropResult } from "react-beautiful-dnd";
import { ICreateTableData } from "../Firebase/firestore";
import {
  IRowNotProcessed,
  ReasonRowFailed,
  alphabeticalNameSort,
  formatGuestnameForSearch,
  getGuestId,
  groupTablesAlphabetically,
  sanitizeString,
} from "../Utils";
import { IDeleteGuest, IUpdateGuestDataInDB, IUpdateTableData, initialState } from "./TableContext";
import { omit } from "lodash";
export const GUEST_ADDED = "GUEST_ADDED";
export const ADD_TABLE_REQUEST = "ADD_TABLE_REQUEST";
export const ADD_TABLE_FAILURE = "ADD_TABLE_FAILURE";
export const ADD_TABLE_SUCCESS = "ADD_TABLE_SUCCESS";
export const DRAG_END = "DRAG_END";
export const TABLE_SEARCHED = "TABLE_SEARCHED";
export const SIDE_PANNEL_SEARCHED = "SIDE_PANNEL_SEARCHED";
export const TOP_PANNEL_SEARCHED = "TOP_PANNEL_SEARCHED";
export const TABLE_COLLAPSED = "TABLE_COLLAPSED";
export const FETCH_TABLES_PENDING = "FETCH_TABLES_PENDING";
export const FETCH_TABLES_SUCCESS = "FETCH_TABLES_SUCCESS";
export const FETCH_TABLES_FAILURE = "FETCH_TABLES_FAILURE";
export const GUEST_REMOVED_FROM_TABLE = "GUEST_REMOVED_FROM_TABLE";
export const GUESTS_UPLOAD_SUCCESS = "GUESTS_UPLOAD_SUCCESS";
export const GUESTS_UPLOAD_FAILURE = "GUESTS_UPLOAD_FAILURE";
export const GUESTS_UPLOAD_REQUEST = "GUESTS_UPLOAD_REQUEST";
export const CLEAR_UPLOAD_MESSAGE = "CLEAR_UPLOAD_MESSAGE";
export const DELETE_GUEST_REQUEST = "DELETE_GUEST_REQUEST";
export const DELETE_GUEST_SUCCESS = "DELETE_GUEST_SUCCESS";
export const DELETE_GUEST_FAILURE = "DELETE_GUEST_FAILURE";
export const MOVE_GUESTS_SUCCESS = "MOVE_GUESTS_SUCCESS";
export const MOVE_GUESTS_LOADING = "MOVE_GUESTS_LOADING";
export const MOVE_GUESTS_FAILURE = "MOVE_GUESTS_FAILURE";
export const TABLE_MODAL_OPENED = "TABLE_MODAL_OPENED";
export const GUEST_MODAL_OPENED = "GUEST_MODAL_OPENED";
export const UPDATE_TABLE_REQUEST = "UPDATE_TABLE_REQUEST";
export const UPDATE_TABLE_SUCCESS = "UPDATE_TABLE_SUCCESS";
export const UPDATE_TABLE_FAILURE = "UPDATE_TABLE_FAILURE";
export const DELETE_TABLE_REQUEST = "DELETE_TABLE_REQUEST";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";
export const DELETE_TABLE_FAILURE = "DELETE_TABLE_FAILURE";
export const UPDATE_GUEST_REQUEST = "UPDATE_GUEST_REQUEST";
export const UPDATE_GUEST_SUCCESS = "UPDATE_GUEST_SUCCESS";
export const UPDATE_GUEST_FAILURE = "UPDATE_GUEST_FAILURE";
export const NEXT_SEARCH_RESULT = "NEXT_SEARCH_RESULT";
export const PREVIOUS_SEARCH_RESULT = "PREVIOUS_SEARCH_RESULT";
export const RESET_STATE = "RESET_STATE";

function getUniqueReasonsList(rowsNotProcessed: IRowNotProcessed[]): IRowNotProcessed[] {
  const uniqueReasons = new Map<ReasonRowFailed, IRowNotProcessed>();

  for (const row of rowsNotProcessed) {
    if (!uniqueReasons.has(row.reason)) {
      uniqueReasons.set(row.reason, row);
    }
  }

  return Array.from(uniqueReasons.values());
}

export enum GuestFormat {
  Format1 = "Title,Initial,Surname",
  Format2 = "First Name,Last Name",
}

export const guestFormatInputKeys = {
  [GuestFormat.Format1]: ["initial", "surname", "title"],
  [GuestFormat.Format2]: ["first name", "last name"],
};

export interface TableInput {
  tableName: string;
  capacity: number;
  tableNumber: number;
}
type TGenericGuestFormat = {
  id: string;
};

type TGuestFormat1 = { format?: GuestFormat.Format1; initial: string; title: string; lastName: string } & TGenericGuestFormat;
type TGuestFormat2 = { format?: GuestFormat.Format2; firstName: string; lastName: string } & TGenericGuestFormat;

export type TGuest = TGuestFormat1 | TGuestFormat2;

type TGuestAdded = {
  type?: typeof GUEST_ADDED;
  guest: TGuest;
};

export type TGuestsUploadSuccess = {
  type?: typeof GUESTS_UPLOAD_SUCCESS;
  rowsNotProcessed?: IRowNotProcessed[];
  guests: TGuest[];
};

export type TGuestsUploadRequest = {
  type?: typeof GUESTS_UPLOAD_REQUEST;
};

export type TGuestsUploadFailure = {
  type: typeof GUESTS_UPLOAD_FAILURE;
  error?: string;
};

export type TTableCollapsed = {
  type?: typeof TABLE_COLLAPSED;
  tableId: string;
};

export type TMoveGuestsLoading = {
  type?: typeof MOVE_GUESTS_LOADING;
};

export type TMoveGuestsSuccess = {
  type?: typeof MOVE_GUESTS_SUCCESS;
};

export type TMoveGuestsFailure = {
  type?: typeof MOVE_GUESTS_FAILURE;
};

export type TAddTableRequest = {
  type?: typeof ADD_TABLE_REQUEST;
};

export type TAddTableSuccess = {
  type?: typeof ADD_TABLE_SUCCESS;
  payload: ICreateTableData[];
};

export type TAddTableFailure = {
  type?: typeof ADD_TABLE_FAILURE;
};

export type TTableSearched = {
  type?: typeof TABLE_SEARCHED;
  searchText: string;
};

export type TOnDragEnd = {
  type?: typeof DRAG_END;
  dropResult: DropResult;
};

export type TSidePannelSearched = {
  type?: typeof SIDE_PANNEL_SEARCHED;
  searchText: string;
};

export type TGuestDeletedRequest = {
  type?: typeof DELETE_GUEST_REQUEST;
};
export type TGuestDeletedFailure = {
  type?: typeof DELETE_GUEST_FAILURE;
};
export type TGuestDeletedSuccess = {
  type?: typeof DELETE_GUEST_SUCCESS;
  payload: IDeleteGuest;
};

export type TTopPannelSearched = {
  type?: typeof TOP_PANNEL_SEARCHED;
  searchText: string;
};

export type TFetchTables = {
  type?: typeof FETCH_TABLES_PENDING;
};

export type TFetchTablesSuccess = {
  type?: typeof FETCH_TABLES_SUCCESS;
  payload: {
    tables: TTableMap;
  };
};
export type TFetchTablesFailure = {
  type?: typeof FETCH_TABLES_FAILURE;
  error?: string;
};
export type TUpdateTables = {
  type?: typeof UPDATE_TABLE_REQUEST;
};

export type TUpdateTablesSuccess = {
  type?: typeof UPDATE_TABLE_SUCCESS;
  payload: IUpdateTableData;
};
export type TUpdateTablesFailure = {
  type?: typeof UPDATE_TABLE_FAILURE;
};

export type TDeleteTable = {
  type?: typeof DELETE_TABLE_REQUEST;
};

export type TDeleteTableSuccess = {
  type?: typeof DELETE_TABLE_SUCCESS;
};
export type TDeleteTableFailure = {
  type?: typeof DELETE_TABLE_FAILURE;
};

export type TUpdateTGuest = {
  type?: typeof UPDATE_GUEST_REQUEST;
};

export type TUpdateGuestSuccess = {
  type?: typeof UPDATE_GUEST_SUCCESS;
  payload: IUpdateGuestDataInDB;
};
export type TUpdateGuestFailure = {
  type?: typeof UPDATE_GUEST_FAILURE;
  error?: string;
};

export type TNextSearchResult = {
  type: typeof NEXT_SEARCH_RESULT;
};

export type TPreviousSearchResult = {
  type: typeof PREVIOUS_SEARCH_RESULT;
};
export type TGuestRemovedFromTable = {
  type?: typeof GUEST_REMOVED_FROM_TABLE;
  tableId: string;
  guestId: string;
};
export type TableModalOpened = {
  type: typeof TABLE_MODAL_OPENED;
  tableId: string;
};
export type GuestModalOpened = {
  type: typeof GUEST_MODAL_OPENED;
  guestId: string;
  tableId: string;
};

export type TClearUploadMessage = {
  type: typeof CLEAR_UPLOAD_MESSAGE;
};

export type TResetState = {
  type: typeof RESET_STATE;
};

export type TAction =
  | TGuestAdded
  | TAddTableRequest
  | TOnDragEnd
  | TTableSearched
  | TSidePannelSearched
  | TTopPannelSearched
  | TTableCollapsed
  | TFetchTables
  | TFetchTablesSuccess
  | TFetchTablesFailure
  | TGuestRemovedFromTable
  | TGuestsUploadSuccess
  | TGuestsUploadFailure
  | TGuestsUploadRequest
  | TGuestDeletedFailure
  | TGuestDeletedRequest
  | TGuestDeletedSuccess
  | TAddTableSuccess
  | TAddTableFailure
  | TMoveGuestsFailure
  | TMoveGuestsLoading
  | TMoveGuestsSuccess
  | TableModalOpened
  | TUpdateTables
  | TUpdateTablesFailure
  | TUpdateTablesSuccess
  | TDeleteTable
  | TDeleteTableFailure
  | TDeleteTableSuccess
  | TUpdateGuestFailure
  | TUpdateGuestSuccess
  | TUpdateTGuest
  | GuestModalOpened
  | TPreviousSearchResult
  | TNextSearchResult
  | TClearUploadMessage
  | TResetState;

export type TTable = {
  tableName?: string;
  capacity: number;
  guests: TGuest[];
  tableNumber: number;
  tableId: string;
};

export type TGuestPath = { [key: string]: string[] };
export type TTableMap = { [key: string]: TTable };

export interface ITableReducerState {
  tables: TTableMap;
  sidePannelSearchText: string;
  topPannelSearchText: string;
  collapsedTables: string[];
  tableToScrollIntoView: TTable | undefined;
  guestIdToScrollIntoView?: string;
  fetchTablesLoading: boolean;
  fetchTablesFailure?: string;
  uploadGuestsError?: string;
  guestsNeedSaving: boolean;
  uploadingGuests: boolean;
  deletingGuest: boolean;
  addingTable: boolean;
  updatingTable: boolean;
  deletingTable: boolean;
  updatingGuest: boolean;
  updatingGuestFailure?: string;
  guestPaths: TGuestPath;
  moveGuestsLoading: boolean;
  moveGuestsError: string | null;
  tableOpenInModel: string;
  guestOpenInModal: string;
  tableOfguestOpenInModal: string;
  reasonForRowsNotProcessedAfterUpload?: IRowNotProcessed[];
  searchInfo: ITableSearchInfo[];
}

export interface ITableSearchInfo {
  tableId: string;
  guestId: string;
  guestIndex: number;
}

export const guestMatchesSearchText = (guest: TGuest, filterText: string): boolean => {
  const fullname = formatGuestnameForSearch(guest);
  const sanitizedFilterText = sanitizeString(filterText);
  return fullname.includes(sanitizedFilterText);
};

export const tableReducer = (state: ITableReducerState, action: TAction): ITableReducerState => {
  const { tables, sidePannelSearchText, collapsedTables, guestPaths } = state;
  switch (action.type) {
    case ADD_TABLE_REQUEST: {
      return {
        ...state,
        addingTable: true,
      };
    }
    case ADD_TABLE_FAILURE: {
      return {
        ...state,
        addingTable: false,
      };
    }
    case MOVE_GUESTS_SUCCESS: {
      return {
        ...state,
        guestsNeedSaving: false,
        moveGuestsLoading: false,
        guestPaths: {},
      };
    }
    case MOVE_GUESTS_FAILURE: {
      return {
        ...state,
        moveGuestsLoading: false,
      };
    }
    case ADD_TABLE_SUCCESS: {
      const { payload } = action;
      const newTables = payload.reduce((acc, cur) => {
        const { tableId } = cur;
        return {
          ...acc,
          [tableId]: { capacity: cur.capacity, tableName: cur.tableName, guests: [], tableNumber: cur.tableNumber, tableId: tableId },
        };
      }, {});
      return {
        ...state,
        addingTable: false,
        tables: {
          ...state.tables,
          ...newTables,
        },
      };
    }
    case DRAG_END: {
      const { destination, source, draggableId } = action.dropResult;
      if (sidePannelSearchText && source.droppableId === "sidePannel" && destination?.droppableId === "sidePannel") {
        return state;
      }
      if (!destination) {
        return state;
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return state;
      }

      const sourceTable = tables[source.droppableId];
      const guestAtSourceIndex = sourceTable.guests.find((guest) => guest.id === draggableId) as TGuest;
      const sourceTableGuestsToMutate = [...sourceTable.guests].filter((guest) => guest.id !== draggableId);

      if (destination.droppableId === source.droppableId) {
        sourceTableGuestsToMutate.splice(destination.index, 0, guestAtSourceIndex);
        return {
          ...state,
          tables: { ...tables, [destination.droppableId]: { ...sourceTable, guests: sourceTableGuestsToMutate } },
          topPannelSearchText: "",
          searchInfo: [],
          tableToScrollIntoView: undefined,
          guestIdToScrollIntoView: undefined,
        };
      }
      const destinationTable = tables[destination.droppableId];
      const destinationTableGuestsToMutate = [...destinationTable.guests];
      destinationTableGuestsToMutate.splice(destination.index, 0, guestAtSourceIndex);
      const newGuestPaths = {
        ...guestPaths,
        [draggableId]: guestPaths[draggableId]
          ? [...guestPaths[draggableId], destination.droppableId]
          : [source.droppableId, destination.droppableId],
      };
      return {
        ...state,
        tables: {
          ...tables,
          [destination.droppableId]: { ...destinationTable, guests: destinationTableGuestsToMutate },
          [source.droppableId]: { ...sourceTable, guests: sourceTableGuestsToMutate },
        },
        topPannelSearchText: "",
        sidePannelSearchText: "",
        searchInfo: [],
        tableToScrollIntoView: undefined,
        guestIdToScrollIntoView: undefined,
        guestsNeedSaving: true,
        guestPaths: newGuestPaths,
      };
    }
    case SIDE_PANNEL_SEARCHED: {
      const { searchText } = action;
      return {
        ...state,
        sidePannelSearchText: searchText,
      };
    }
    case TOP_PANNEL_SEARCHED: {
      const { searchText } = action;
      const { tableToScrollIntoView, guestIdToScrollIntoView } = state;
      let collapsedTablesCopy = [...collapsedTables];
      let newTableToScrollIntoView: TTable | undefined;
      let newGuestIdToScrollIntoView;
      let tableSearchInfo: ITableSearchInfo[] = [];
      const searchAbleTables = Object.values(tables)
        .sort((a, b) => a.tableNumber - b.tableNumber)
        .filter((table) => table.tableId !== "sidePannel");
      if (searchText) {
        tableSearchInfo = searchAbleTables.reduce((acc: ITableSearchInfo[], table: TTable) => {
          const shouldBeIncludedInSearch = table.tableNumber > -1;
          if (shouldBeIncludedInSearch) {
            for (let i = 0; i < table.guests.length; i++) {
              const guest = table.guests[i];
              if (guestMatchesSearchText(guest, searchText)) {
                acc.push({
                  tableId: table.tableId,
                  guestIndex: i + 1,
                  guestId: guest.id,
                });
              }
            }
          }
          return acc;
        }, []);
        const shouldSetTableToScrollIntoView = tableSearchInfo.length > 0 && !tableToScrollIntoView;
        if (shouldSetTableToScrollIntoView) {
          newTableToScrollIntoView = tables[tableSearchInfo[0].tableId];
        } else if (tableToScrollIntoView && tableSearchInfo.length) {
          const iscurrentTablePartOfSearch = tableSearchInfo.find((tableInfo) => {
            return tableInfo.tableId === tableToScrollIntoView.tableId;
          });
          if (iscurrentTablePartOfSearch) {
            newTableToScrollIntoView = tableToScrollIntoView;
          } else {
            newTableToScrollIntoView = tables[tableSearchInfo[0].tableId];
          }
        }
        if (newTableToScrollIntoView) {
          const guestPrevMatched = tables[newTableToScrollIntoView.tableId].guests.find((guest) => guest.id === guestIdToScrollIntoView);
          if (guestPrevMatched && guestMatchesSearchText(guestPrevMatched, searchText)) {
            newGuestIdToScrollIntoView = guestIdToScrollIntoView;
          } else {
            newGuestIdToScrollIntoView = newTableToScrollIntoView.guests.find((guest) => guestMatchesSearchText(guest, searchText))?.id;
          }
        }
        if (newTableToScrollIntoView) {
          collapsedTablesCopy = collapsedTablesCopy.filter((tableId) => tableId !== newTableToScrollIntoView?.tableId);
        }
      }
      return {
        ...state,
        topPannelSearchText: searchText,
        collapsedTables: collapsedTablesCopy,
        tableToScrollIntoView: newTableToScrollIntoView,
        guestIdToScrollIntoView: newGuestIdToScrollIntoView,
        searchInfo: tableSearchInfo,
      };
    }
    case TABLE_COLLAPSED: {
      const { tableId } = action;
      const collapsedTablesToMutate = [...collapsedTables];
      const index = collapsedTablesToMutate.findIndex((id) => id === tableId);
      if (index > -1) {
        collapsedTablesToMutate.splice(index, 1);
      } else {
        collapsedTablesToMutate.push(tableId);
      }
      return {
        ...state,
        collapsedTables: collapsedTablesToMutate,
      };
    }
    case FETCH_TABLES_PENDING: {
      return {
        ...state,
        fetchTablesLoading: true,
      };
    }
    case FETCH_TABLES_FAILURE: {
      return {
        ...state,
        fetchTablesLoading: false,
        fetchTablesFailure: action.error,
      };
    }
    case FETCH_TABLES_SUCCESS: {
      const { tables: tableData } = action.payload;
      const newTables = groupTablesAlphabetically({ ...tables, ...tableData });
      return {
        ...state,
        tables: newTables,
        fetchTablesLoading: false,
      };
    }
    case GUEST_REMOVED_FROM_TABLE: {
      const { tableId, guestId } = action;
      const guest = state.tables[tableId].guests.find((guest) => guest.id === guestId) as TGuest;
      const guestHasBeenMovedSinceSave = !!guestPaths[guestId];
      const newGuestPaths = {
        ...guestPaths,
        [guestId]: guestHasBeenMovedSinceSave ? [...guestPaths[guestId], "sidePannel"] : [tableId, "sidePannel"],
      };

      return {
        ...state,
        topPannelSearchText: state?.tableToScrollIntoView?.tableId === tableId ? "" : state.topPannelSearchText,
        sidePannelSearchText: "",
        guestPaths: newGuestPaths,
        guestsNeedSaving: true,
        tables: {
          ...state.tables,
          [tableId]: { ...state.tables[tableId], guests: state.tables[tableId].guests.filter((guest) => guest.id !== guestId) },
          sidePannel: {
            ...state.tables.sidePannel,
            guests: [...state.tables.sidePannel.guests, guest],
          },
        },
        searchInfo: [],
        tableToScrollIntoView: undefined,
        guestIdToScrollIntoView: undefined,
      };
    }
    case GUESTS_UPLOAD_SUCCESS: {
      const { guests, rowsNotProcessed } = action;
      return {
        ...state,
        uploadingGuests: false,
        uploadGuestsError: undefined,
        reasonForRowsNotProcessedAfterUpload: getUniqueReasonsList(rowsNotProcessed ?? []),
        tables: {
          ...state.tables,
          sidePannel: {
            ...state.tables.sidePannel,
            guests: [...state.tables.sidePannel.guests, ...guests].sort(alphabeticalNameSort),
          },
        },
      };
    }
    case CLEAR_UPLOAD_MESSAGE: {
      return {
        ...state,
        uploadGuestsError: undefined,
        reasonForRowsNotProcessedAfterUpload: undefined,
      };
    }
    case GUESTS_UPLOAD_REQUEST: {
      return {
        ...state,
        uploadingGuests: true,
        uploadGuestsError: undefined,
        reasonForRowsNotProcessedAfterUpload: undefined,
      };
    }
    case GUESTS_UPLOAD_FAILURE: {
      const { error } = action;
      return { ...state, uploadingGuests: false, uploadGuestsError: error };
    }
    case DELETE_GUEST_REQUEST: {
      return { ...state, deletingGuest: true };
    }
    case DELETE_GUEST_FAILURE: {
      return { ...state, deletingGuest: false };
    }
    case MOVE_GUESTS_LOADING: {
      return {
        ...state,
        moveGuestsLoading: true,
      };
    }
    case DELETE_GUEST_SUCCESS: {
      const { guestId, tableId } = action.payload;
      return {
        ...state,
        searchInfo: [],
        tableToScrollIntoView: undefined,
        guestIdToScrollIntoView: undefined,
        topPannelSearchText: "",
        deletingGuest: false,
        tables: {
          ...state.tables,
          [tableId]: {
            ...state.tables[tableId],
            guests:
              state.tables[tableId].guests?.filter((guest) => {
                return guest.id !== guestId;
              }) ?? [],
          },
        },
      };
    }
    case TABLE_MODAL_OPENED: {
      return {
        ...state,
        tableOpenInModel: action.tableId,
      };
    }
    case UPDATE_TABLE_FAILURE: {
      return {
        ...state,
        updatingTable: false,
      };
    }
    case UPDATE_TABLE_SUCCESS: {
      const { tableId, tableNumber, tableName, capacity } = action.payload;
      return {
        ...state,
        tables: {
          ...state.tables,
          [tableId]: {
            ...state.tables[tableId],
            tableNumber: tableNumber ?? state.tables[tableId].tableNumber,
            tableName: tableName ?? state.tables[tableId].tableName,
            capacity: capacity ?? state.tables[tableId].capacity,
          },
        },
        updatingTable: false,
      };
    }
    case UPDATE_TABLE_REQUEST: {
      return {
        ...state,
        updatingTable: true,
      };
    }
    case UPDATE_GUEST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        updatingGuest: false,
        updatingGuestFailure: error,
      };
    }
    case UPDATE_GUEST_SUCCESS: {
      const { tableId, guestId, ...rest } = action.payload;
      let newGuestId = "";

      const hasGuests = state.tables[tableId]?.guests;
      const newGuests =
        hasGuests &&
        state.tables[tableId]?.guests.map((guest) => {
          if (guest.id === guestId) {
            if (rest.format === GuestFormat.Format2) {
              const newId = getGuestId({ format: GuestFormat.Format2, firstName: rest.firstName, lastName: rest.lastName });
              newGuestId = newId;
              return {
                ...guest,
                firstName: rest.firstName,
                lastName: rest.lastName,
                id: newId,
              };
            }
            if (rest.format === GuestFormat.Format1) {
              const newId = getGuestId({ format: GuestFormat.Format1, title: rest.title, initial: rest.initial, lastName: rest.lastName });
              newGuestId = newId;
              return {
                ...guest,
                title: rest.title,
                initial: rest.initial,
                lastName: rest.lastName,
                id: newId,
              };
            }
          }
          return guest;
        });
      const newGuestPaths = Object.keys(guestPaths).reduce((acc: TGuestPath, cur: string) => {
        if (cur === guestId) {
          return {
            ...acc,
            [newGuestId]: guestPaths[cur],
          };
        }
        return { ...acc, [cur]: guestPaths[cur] };
      }, {});
      return {
        ...state,
        tables: {
          ...state.tables,
          [tableId]: {
            ...state.tables[tableId],
            guests: newGuests,
          },
        },
        updatingGuest: false,
        updatingGuestFailure: undefined,
        guestOpenInModal: newGuestId,
        guestPaths: newGuestPaths,
      };
    }
    case UPDATE_GUEST_REQUEST: {
      return {
        ...state,
        updatingGuest: true,
        updatingGuestFailure: undefined,
      };
    }
    case GUEST_MODAL_OPENED: {
      return {
        ...state,
        guestOpenInModal: action.guestId,
        tableOfguestOpenInModal: action.tableId,
      };
    }
    case DELETE_TABLE_REQUEST: {
      return { ...state, deletingTable: true };
    }
    case DELETE_TABLE_FAILURE: {
      return { ...state, deletingTable: false };
    }
    case DELETE_TABLE_SUCCESS: {
      const { tableOpenInModel } = state;
      let newTables: TTableMap = omit(tables, tableOpenInModel);
      newTables = {
        ...newTables,
        sidePannel: {
          ...newTables.sidePannel,
          guests: [...newTables.sidePannel.guests, ...tables[tableOpenInModel].guests].sort(alphabeticalNameSort),
        },
      };
      return { ...state, deletingTable: false, guestPaths: {}, tables: newTables, guestsNeedSaving: false };
    }
    case NEXT_SEARCH_RESULT: {
      const { searchInfo, tableToScrollIntoView, guestIdToScrollIntoView, collapsedTables } = state;
      let collapsedTablesCopy = [...collapsedTables];
      if (!tableToScrollIntoView || !guestIdToScrollIntoView) {
        return state;
      }
      const currentSearchInfoIndex = searchInfo.findIndex((searchInfo) => {
        return tableToScrollIntoView.tableId === searchInfo.tableId && searchInfo.guestId === guestIdToScrollIntoView;
      });
      const canIncrement = currentSearchInfoIndex >= 0 && currentSearchInfoIndex < searchInfo.length - 1;
      if (canIncrement) {
        const nextSearchResult = searchInfo[currentSearchInfoIndex + 1];
        const currentSearchResult = searchInfo[currentSearchInfoIndex];
        const shouldIncrementGuest = nextSearchResult.tableId === currentSearchResult.tableId;
        if (shouldIncrementGuest) {
          return {
            ...state,
            guestIdToScrollIntoView: nextSearchResult.guestId,
          };
        }
        collapsedTablesCopy = collapsedTablesCopy.filter((tableId) => nextSearchResult.tableId !== tableId);
        return {
          ...state,
          tableToScrollIntoView: Object.values(tables).find((table) => table.tableId === nextSearchResult.tableId),
          guestIdToScrollIntoView: nextSearchResult.guestId,
          collapsedTables: collapsedTablesCopy,
        };
      }
      return state;
    }
    case PREVIOUS_SEARCH_RESULT: {
      const { searchInfo, tableToScrollIntoView, guestIdToScrollIntoView, collapsedTables } = state;
      let collapsedTablesCopy = [...collapsedTables];
      if (!tableToScrollIntoView || !guestIdToScrollIntoView) {
        return state;
      }
      const currentSearchInfoIndex = searchInfo.findIndex((searchInfo) => {
        return tableToScrollIntoView.tableId === searchInfo.tableId && searchInfo.guestId === guestIdToScrollIntoView;
      });
      const canDecrement = currentSearchInfoIndex > 0;
      if (canDecrement) {
        const previousSearchResult = searchInfo[currentSearchInfoIndex - 1];
        const currentSearchResult = searchInfo[currentSearchInfoIndex];
        const shouldDecrementGuest = previousSearchResult.tableId === currentSearchResult.tableId;
        if (shouldDecrementGuest) {
          return {
            ...state,
            guestIdToScrollIntoView: previousSearchResult.guestId,
          };
        }
        collapsedTablesCopy = collapsedTablesCopy.filter((tableId) => previousSearchResult.tableId !== tableId);
        return {
          ...state,
          tableToScrollIntoView: Object.values(tables).find((table) => table.tableId === previousSearchResult.tableId),
          guestIdToScrollIntoView: previousSearchResult.guestId,
          collapsedTables: collapsedTablesCopy,
        };
      }
      return state;
    }
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};
