import styled from "styled-components";
import { TextStyles, Theme } from "../Theme";

export const AddGuestsFormContainer = styled.div`
  display: flex;
  width: 550px;
  height: 450px;
  flex-direction: column;
`;

export const AddGuestsFormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 15px;
`;

export const StyledButtonBottomFlex = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const StyledDownloadButtonText = styled.div`
  ${TextStyles.regular};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-left: 8px;
`;

export const StyledFormStepExplanation = styled.p<{ color?: string; marginBottom?: string }>`
  ${TextStyles.regular}
  margin-bottom: ${(props) => props.marginBottom ?? "30px"};
  color: ${(props) => props.color ?? Theme.colors.blackGrey};
  line-height: 20px;
`;

export const StyledFormatText = styled.span`
  ${TextStyles.regular};
  color: ${Theme.colors.black};
  font-weight: bold;
  font-style: italic;
`;
