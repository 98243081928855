export const USER_SIGN_UP_FAILURE = "USER_SIGN_UP_FAILURE";
export const USER_SIGN_UP_PENDING = "USER_SIGN_UP_PENDING";
export const USER_SIGN_IN_FAILURE = "USER_SIGN_IN_FAILURE";
export const USER_SIGN_IN_PENDING = "USER_SIGN_IN_PENDING";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_PENDING = "USER_LOGOUT_PENDING";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";
export const NAVIGATE_FROM_AUTH_FORM = "NAVIGATE_FROM_AUTH_FORM";
export const USER_AUTH_PENDING = "USER_AUTH_PENDING";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_AUTH_FAILURE = "USER_AUTH_FAILURE";
export const USER_UPDATE_PENDING = "USER_UPDATE_PENDING";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export interface IUserUpdateableFields {
  firstName: string;
  lastName: string;
}

export type TUserSignupFailure = {
  type?: typeof USER_SIGN_UP_FAILURE;
  error: string;
};

export type TUserSignupPending = {
  type?: typeof USER_SIGN_UP_PENDING;
};

export type TUserSigninFailure = {
  type?: typeof USER_SIGN_IN_FAILURE;
  error: string;
};

export type TUserSigninPending = {
  type?: typeof USER_SIGN_IN_PENDING;
};

export type TUserLogoutSuccess = {
  type?: typeof USER_LOGOUT_SUCCESS;
};

export type TUserLogoutFailure = {
  type?: typeof USER_LOGOUT_FAILURE;
  error: string;
};

export type TUserLogoutPending = {
  type?: typeof USER_LOGOUT_PENDING;
};

export type TNavigateFromAuthForm = {
  type?: typeof NAVIGATE_FROM_AUTH_FORM;
};

export type TUserAuthPending = {
  type: typeof USER_AUTH_PENDING;
};

export type TUserAuthSuccess = {
  type: typeof USER_AUTH_SUCCESS;
  user: IUser;
};

export type TUserAuthFailure = {
  type: typeof USER_AUTH_FAILURE;
  error: string;
};

export type TUserUpdatePending = {
  type: typeof USER_UPDATE_PENDING;
};

export type TUserUpdateSuccess = {
  type: typeof USER_UPDATE_SUCCESS;
  userUpdateableFields: IUserUpdateableFields;
};

export type TUserUpdateFailure = {
  type: typeof USER_UPDATE_FAILURE;
  error: string;
};

export type TAction =
  | TUserSignupFailure
  | TUserSignupPending
  | TUserSigninPending
  | TUserSigninFailure
  | TNavigateFromAuthForm
  | TUserLogoutSuccess
  | TUserLogoutFailure
  | TUserLogoutPending
  | TUserAuthPending
  | TUserAuthSuccess
  | TUserAuthFailure
  | TUserUpdatePending
  | TUserUpdateSuccess
  | TUserUpdateFailure;

export interface IUser {
  email: string;
  uid: string;
  firstName: string;
  lastName: string;
  accountCreationTime: { seconds: number };
  hasPaid?: boolean;
  processingComplete?: boolean;
}

export interface IAuthReducerState {
  user?: IUser;
  authPending: boolean;
  authPendingError?: string;
  updatePending: boolean;
  updateError?: string;
  signupLoading: boolean;
  signinLoading: boolean;
  logoutLoading: boolean;
  signupError?: string;
  signinError?: string;
  logoutError?: string;
}

const friendlyErrorMessage: { [key: string]: string } = {
  "auth/email-already-in-use": "This email address is already in use",
  "auth/network-request-failed": "No network connection",
  "auth/user-not-found": "A user with this email does not exist",
  "auth/wrong-password": "Incorrect password",
};

export const authReducer = (state: IAuthReducerState, action: TAction): IAuthReducerState => {
  switch (action.type) {
    case USER_SIGN_UP_FAILURE: {
      return {
        ...state,
        signupLoading: false,
        user: undefined,
        signupError: friendlyErrorMessage[action.error] || action.error,
      };
    }
    case USER_SIGN_UP_PENDING: {
      return {
        ...state,
        signupLoading: true,
        signupError: undefined,
      };
    }
    case USER_SIGN_IN_FAILURE: {
      return {
        ...state,
        signinLoading: false,
        user: undefined,
        signinError: friendlyErrorMessage[action.error] || action.error,
      };
    }
    case USER_SIGN_IN_PENDING: {
      return {
        ...state,
        signinLoading: true,
        signinError: undefined,
      };
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        user: undefined,
        authPending: false,
        logoutLoading: false,
        logoutError: undefined,
      };
    }
    case USER_LOGOUT_PENDING: {
      return {
        ...state,
        logoutLoading: true,
        logoutError: undefined,
      };
    }
    case USER_LOGOUT_FAILURE: {
      return {
        ...state,
        logoutLoading: false,
        logoutError: friendlyErrorMessage[action.error] || action.error,
      };
    }
    case NAVIGATE_FROM_AUTH_FORM: {
      return {
        ...state,
        logoutError: undefined,
        signinError: undefined,
        signupError: undefined,
        authPendingError: undefined,
      };
    }
    case USER_AUTH_PENDING: {
      return {
        ...state,
        authPending: true,
        authPendingError: undefined,
      };
    }
    case USER_AUTH_SUCCESS: {
      const { user } = action;
      return {
        ...state,
        authPending: false,
        authPendingError: undefined,
        signinError: undefined,
        signupError: undefined,
        signupLoading: false,
        signinLoading: false,
        user,
      };
    }
    case USER_AUTH_FAILURE: {
      return {
        ...state,
        authPending: false,
        authPendingError: "There was a problem processing your request. Please contact support.",
        signinError: undefined,
        signupError: undefined,
        signupLoading: false,
        signinLoading: false,
      };
    }
    case USER_UPDATE_PENDING: {
      return {
        ...state,
        updatePending: true,
        updateError: undefined,
      };
    }
    case USER_UPDATE_SUCCESS: {
      const { userUpdateableFields } = action;
      return {
        ...state,
        updatePending: false,
        updateError: undefined,
        user: state.user && { ...state.user, ...userUpdateableFields },
      };
    }
    case USER_UPDATE_FAILURE: {
      return {
        ...state,
        updatePending: false,
        updateError: action.error,
      };
    }

    default: {
      return state;
    }
  }
};
