import { useContext } from "react";
import { TextInputField, Button } from "..";
import { AuthContext } from "../../Context/AuthContext";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import {
  StyledAuthContainer,
  StyledAuthForm,
  StyledWelcomeText,
  StyledRegularAuthText,
  StyledHaveAccountText,
  StyledAuthFormContainer,
  StyledAuthLink,
  AuthLinkContainer,
  StyledErrorText,
  ButtonAndFormErrorContainer,
} from "../../Styles";
import { Theme } from "../../Theme";
import { Formik, Field, FieldProps } from "formik";
import * as Yup from "yup";

const textInputStyle = {
  inputFieldStyle: { focusOutlineStyle: `solid ${Theme.colors.darkBlue} 2px`, height: "35px", backgroundColor: Theme.colors.white },
  inputFieldAndErrorDisplayContainerStyle: { width: "100%", marginTop: "15px" },
};

const buttonStyles = {
  width: "100%",
  color: Theme.colors.white,
  borderRadius: "15px",
  height: "40px",
};

const signinSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Passowrd must be at least 6 characters").required("Required"),
});

export const Login = () => {
  const {
    signin,
    state: { signinLoading, signinError, authPendingError },
    navigateFromAuthForm,
  } = useContext(AuthContext);
  const errorToShowUser = signinError || authPendingError;
  return (
    <StyledAuthContainer>
      <StyledAuthFormContainer>
        <Logo width={"80"} height={"80"} />
        <StyledWelcomeText>Hey, good to see you again!</StyledWelcomeText>
        <StyledRegularAuthText>Sign in to get going</StyledRegularAuthText>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            const { email, password } = values;
            signin({ email, password });
          }}
          validationSchema={signinSchema}
        >
          {({ errors, dirty }) => {
            return (
              <StyledAuthForm>
                <Field name="email">
                  {({ field, meta }: FieldProps) => {
                    return <TextInputField label="Email" overrideStyles={textInputStyle} placeholder="rob@example.com" {...field} {...meta} />;
                  }}
                </Field>
                <Field name="password">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <TextInputField
                        label="Password"
                        overrideStyles={textInputStyle}
                        placeholder="Enter your password"
                        type="password"
                        {...field}
                        {...meta}
                      />
                    );
                  }}
                </Field>
                <ButtonAndFormErrorContainer>
                  {errorToShowUser && <StyledErrorText>{errorToShowUser}</StyledErrorText>}
                  <Button isLoading={signinLoading} type="submit" buttonStyles={buttonStyles} text="Sign in" />
                </ButtonAndFormErrorContainer>
              </StyledAuthForm>
            );
          }}
        </Formik>
        <AuthLinkContainer>
          <StyledHaveAccountText>Don’t have an account?</StyledHaveAccountText>
          <StyledAuthLink to="/signup">Sign up</StyledAuthLink>
        </AuthLinkContainer>
        <AuthLinkContainer>
          <StyledHaveAccountText>Forgot your password?</StyledHaveAccountText>
          <StyledAuthLink to="/reset-password" onClick={navigateFromAuthForm}>
            Reset
          </StyledAuthLink>
        </AuthLinkContainer>
      </StyledAuthFormContainer>
    </StyledAuthContainer>
  );
};
