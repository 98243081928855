import { isEqual } from "lodash";
import { createSelector, createSelectorCreator, defaultMemoize } from "reselect";
import { ITableReducerState, TTable, TGuest, TTableMap, guestMatchesSearchText } from "../Context/tableReducer";
import { formatGuestFullname, sortGuestsAlphabetically } from "../Utils";

export interface ISeatInfo {
  tableNumberAsString: string;
  lastName: string;
  fullname: string;
}
export interface IAlphabeticalList {
  letter: string;
  list: ISeatInfo[];
}

export interface ITableNumberList {
  tableNumberAsString: string;
  list: ISeatInfo[];
}
export interface IDownloadLists {
  alphabeticalList: IAlphabeticalList[];
  tableNumberList: ITableNumberList[];
}

interface ISelectDownloadListAcc {
  alphabetical: { [key: string]: ISeatInfo[] };
  tableNumber: { [key: string]: ISeatInfo[] };
}

const customCreateSelector = createSelectorCreator(defaultMemoize, isEqual);
const selectTablesFromState = (state: ITableReducerState) => state.tables;
const selectTableOpenInModalFromState = (state: ITableReducerState) => state.tableOpenInModel;
const selectGuestOpenInModalFromState = (state: ITableReducerState) => state.guestOpenInModal;
const selectTableOfGuestOpenInModal = (state: ITableReducerState) => state.tableOfguestOpenInModal;
const selectTopPannelSearchTextFromState = (state: ITableReducerState) => state.topPannelSearchText;
const selectTableToScrollIntoView = (state: ITableReducerState) => state.tableToScrollIntoView;
const selectCollapsedTables = (state: ITableReducerState) => state.collapsedTables;
const selectFetchingTables = (state: ITableReducerState) => state.fetchTablesLoading;
const selectFetchingTablesFailure = (state: ITableReducerState) => state.fetchTablesFailure;
const selectSearchInfo = (state: ITableReducerState) => state.searchInfo;
const selectGuestIdToScrollIntoView = (state: ITableReducerState) => state.guestIdToScrollIntoView;

export const selectTables = createSelector(selectTablesFromState, (tables): TTableMap => {
  return Object.entries(tables)
    .sort((a, b) => a[1].tableNumber - b[1].tableNumber)
    .reduce((acc, cur) => {
      const [key, table] = cur;
      if (key !== "sidePannel") {
        return { ...acc, [key]: table };
      }
      return acc;
    }, {});
});

export const selectGuestsForSidePannel: (state: ITableReducerState, filterText: string) => TGuest[] = createSelector(
  [selectTablesFromState, (state, filterText) => filterText],
  (tables, filterText) => {
    return (
      tables.sidePannel?.guests.filter((guest) => {
        return guestMatchesSearchText(guest, filterText);
      }) ?? []
    );
  }
);

export const selectShouldScrollIntoView: (state: ITableReducerState, tableId: number) => boolean = createSelector(
  [selectTableToScrollIntoView, (state, tableId) => tableId],
  (tableToScrollIntoView, tableId) => {
    return tableToScrollIntoView?.tableId === tableId;
  }
);

export const selectShouldShowSearchArrows: (state: ITableReducerState) => boolean = createSelector(
  [selectTableToScrollIntoView],
  (tableToScrollIntoView) => {
    return !!tableToScrollIntoView;
  }
);

export const selectTableRowToScrollTo: (state: ITableReducerState) => number | undefined = createSelector(
  [selectGuestIdToScrollIntoView, selectSearchInfo],
  (guestIdToScrollIntoView, searchInfo) => {
    return searchInfo.find((info) => info.guestId === guestIdToScrollIntoView)?.guestIndex;
  }
);

export const topPannelSearchTextSelector: (state: ITableReducerState) => string = createSelector(
  [selectTopPannelSearchTextFromState],
  (searchText) => {
    return searchText;
  }
);

export const selectIsTableCollapsed: (state: ITableReducerState, tableId: string) => boolean = createSelector(
  [selectCollapsedTables, (state, tableId) => tableId],
  (collapsedTables, tableId) => {
    return collapsedTables.includes(tableId);
  }
);

export const selectTableNumbers: (state: ITableReducerState) => number[] = createSelector([selectTablesFromState], (tables) => {
  return Object.values(tables).reduce((acc: number[], cur: TTable) => {
    if (cur.tableId !== "sidePannel") {
      return [...acc, cur.tableNumber];
    }
    return acc;
  }, []);
});

export const selectGuestsForTable: (state: ITableReducerState, tableId: string) => TGuest[] = customCreateSelector(
  [selectTablesFromState, (state, tableId) => tableId],
  (tables, tableId) => {
    return tables[tableId] ? tables[tableId].guests : [];
  }
);

export const selectTableOpenInModal: (state: ITableReducerState) => TTable = customCreateSelector(
  [selectTablesFromState, selectTableOpenInModalFromState],
  (tables, tableId) => {
    return tables[tableId];
  }
);

export const selectShouldShowDeleteConfirmation = customCreateSelector(
  [selectTablesFromState, selectTableOpenInModalFromState],
  (tables, tableId) => {
    return tables[tableId]?.guests?.length > 0;
  }
);

export const selectGuestOpenInModal: (state: ITableReducerState) => TGuest = customCreateSelector(
  [selectTableOfGuestOpenInModal, selectGuestOpenInModalFromState, selectTablesFromState],
  (tableOfguestOpenInModal, guestOpenInModal, tables) => {
    const tableToSearch = tables[tableOfguestOpenInModal];
    const guest = tableToSearch.guests.find((guest) => {
      return guest.id === guestOpenInModal;
    });
    return guest!;
  }
);

export const selectShouldShowDropZone: (state: ITableReducerState, tableId: string) => boolean = createSelector(
  [selectIsTableCollapsed, selectGuestsForTable],
  (isTableCollapsed, guests) => {
    return isTableCollapsed || (guests && guests.length === 0);
  }
);

export const selectdownlaodLists: (state: ITableReducerState) => IDownloadLists = createSelector([selectTablesFromState], (tables) => {
  const downloadObjLists = Object.values(tables).reduce(
    (acc: ISelectDownloadListAcc, cur: TTable) => {
      for (let i = 0; i < cur.guests.length; i++) {
        const guest = cur.guests[i];
        const lastName = cur.guests[i].lastName;
        const lastNameLetter = lastName[0]?.toLocaleLowerCase() || "-";
        const fullname = formatGuestFullname(guest);
        const tableNumberAsString = `Table ${cur.tableNumber}`;
        const tableNumber = cur.tableNumber;
        if (cur.tableId !== "sidePannel") {
          if (acc.alphabetical[lastNameLetter]) {
            acc.alphabetical[lastNameLetter] = [...acc.alphabetical[lastNameLetter], { fullname, lastName, tableNumberAsString }];
          } else {
            acc.alphabetical[`${lastNameLetter}`] = [{ fullname, tableNumberAsString, lastName }];
          }
          if (acc.tableNumber[`${tableNumber}`]) {
            acc.tableNumber[`${tableNumber}`] = [...acc.tableNumber[`${tableNumber}`], { fullname, tableNumberAsString, lastName }];
          } else {
            acc.tableNumber[`${tableNumber}`] = [{ fullname, tableNumberAsString, lastName }];
          }
        }
      }
      return acc;
    },
    { alphabetical: {}, tableNumber: {} }
  );
  const alphabeticalList = Object.keys(downloadObjLists.alphabetical)
    .sort()
    .map((letter) => ({ letter, list: sortGuestsAlphabetically(downloadObjLists.alphabetical[letter]) }));
  const tableNumberList = Object.keys(downloadObjLists.tableNumber)
    .sort((a, b) => Number(a) - Number(b[1]))
    .map((tableNumber) => ({
      tableNumberAsString: `Table ${tableNumber}`,
      list: sortGuestsAlphabetically(downloadObjLists.tableNumber[tableNumber]),
    }));
  return { alphabeticalList, tableNumberList };
});

export const selectIsFetchingTables = createSelector([selectFetchingTables], (isFetching) => isFetching);

export const selectErrorWhileFetchingTables = createSelector([selectFetchingTablesFailure], (isFetching) => isFetching);

export const selectSearchTextInfo = customCreateSelector(
  [selectSearchInfo, selectTableToScrollIntoView, selectGuestIdToScrollIntoView],
  (searchInfo, tableToScrollIntoView, guestIdToScrollIntoView) => {
    if (searchInfo.length > 0 && tableToScrollIntoView) {
      return {
        totalMatches: searchInfo.length,
        indexOfCurrentMatch:
          searchInfo.findIndex((info) => info.tableId === tableToScrollIntoView?.tableId && info.guestId === guestIdToScrollIntoView) + 1,
      };
    }
  }
);

export const selectShouldShowTopPannelSearch = createSelector([selectTables], (tableMap) => {
  return Object.keys(tableMap).length > 0;
});

export const selectNumberOfTables = createSelector([selectTablesFromState], (tables) => {
  return Object.keys(tables).length - 1;
});

export const selectNumberOfGuests = createSelector([selectTablesFromState], (tables) => {
  return Object.keys(tables).reduce((acc: number, cur) => {
    acc += tables[cur].guests.length;
    return acc;
  }, 0);
});

export const selectNumberOfAssignedGuests = createSelector([selectTablesFromState], (tables) => {
  return Object.keys(tables).reduce((acc: number, cur) => {
    if (cur !== "sidePannel") {
      acc += tables[cur].guests.length;
    }
    return acc;
  }, 0);
});
export const selectAlphabeticalListForDesktop = createSelector([selectdownlaodLists], ({ alphabeticalList }) => {
  const baseMaxGuestsPerTable = 15;
  const flexibilityThreshold = 5;

  const formattedList = alphabeticalList.reduce((newList: IAlphabeticalList[], letter: IAlphabeticalList) => {
    const guestCount = letter.list.length;

    if (guestCount <= baseMaxGuestsPerTable + flexibilityThreshold) {
      return newList.concat(letter);
    }

    const numTables = Math.ceil(guestCount / baseMaxGuestsPerTable);
    const optimalGuestsPerTable = Math.ceil(guestCount / numTables);

    for (let i = 0; i < numTables; i++) {
      const startIndex = optimalGuestsPerTable * i;
      const endIndex = Math.min(startIndex + optimalGuestsPerTable, guestCount);
      newList.push({
        letter: letter.letter,
        list: letter.list.slice(startIndex, endIndex),
      });
    }

    return newList;
  }, []);

  return formattedList;
});
